var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "spiral-drawing" },
    [
      _c(
        "PlayLayout",
        {
          scopedSlots: _vm._u(
            [
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "spiral-drawing__info" },
                      [
                        _vm.identity
                          ? _c(
                              "AppUserCard",
                              {
                                staticClass: "spiral-drawing__user",
                                style: !_vm.mission.instructions
                                  ? "height: 90%"
                                  : null,
                                attrs: { user: _vm.scribe, "media-hidden": "" },
                              },
                              [
                                _c("UserMedia", {
                                  staticClass: "spiral-drawing__user-media",
                                  attrs: {
                                    identity: _vm.identity,
                                    imageUrl: _vm.imageUrl,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.mission.instructions
                          ? _c("ResizableText", {
                              staticClass: "spiral-drawing__text",
                              attrs: { message: _vm.mission.instructions },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              !_vm.isPresenter
                ? {
                    key: "right",
                    fn: function () {
                      return [
                        _c("DrawingPicker", {
                          attrs: {
                            sizes: false,
                            clearTeam: false,
                            brushSize: 2,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          (_vm.isPresenter || _vm.isViewerHostLike ? !!_vm.scribe : true)
            ? _c("Drawable", {
                ref: "drawable",
                attrs: {
                  bgColor: "#fff",
                  fill: "#fff",
                  canDraw: "",
                  height: _vm.height,
                  width: _vm.width,
                  lineWidth: _vm.lineWidth,
                  alpha: 1,
                  lineColorRgb: _vm.color,
                  pen: _vm.pen,
                  points: _vm.points,
                },
                on: { mouseUp: _vm.onMouseUp },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isViewerScribe
        ? _c("TwilioRoom", {
            key: _vm.identity,
            attrs: {
              roomId: _vm.roomId,
              userId: _vm.identity,
              video: "",
              constraints: _vm.constraints,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }