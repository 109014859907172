























































import { defineComponent } from "@vue/composition-api"

import Drawable from "@/components/Drawable/Drawable.vue"
import ModeSlideTransition from "@/components/GroupTeams/Common/GameModes/ModeSlideTransition.vue"
import SlideFadeTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import TwilioRoom from "@/components/Twilio/TwilioRoom.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"

import useSpiralDrawing from "./useSpiralDrawing"

export default defineComponent({
  name: "SpiralDrawing",
  components: {
    AppUserCard,
    UserMedia,
    SlideFadeTransition,
    ModeSlideTransition,
    PlayLayout,
    ResizableText,
    Drawable,
    TwilioRoom,
    DrawingPicker: () =>
      import("@/components/GroupTeams/Common/GameModes/DrawingPicker.vue")
  },
  setup() {
    const {
      scribe,
      points,
      mission,
      color,
      pen,
      onMouseUp,
      drawable,
      width,
      height,
      identity,
      imageUrl,
      isViewerScribe,
      roomId,
      constraints,
      lineWidth,
      isViewerHostLike,
      isPresenter
    } = useSpiralDrawing()

    return {
      isPresenter,
      isViewerHostLike,
      scribe,
      points,
      mission,
      color,
      pen,
      onMouseUp,
      drawable,
      width,
      height,
      identity,
      imageUrl,
      isViewerScribe,
      roomId,
      constraints,
      lineWidth
    }
  }
})
